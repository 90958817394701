import "typeface-open-sans";
import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

import { getScreenWidth, timeoutThrottlerHandler } from "../utils/helpers";
import Footer from "../components/Footer/";
import Header from "../components/Header";
import UsIcon from '../images/svg-icons/us.svg';
import SaIcon from '../images/svg-icons/sa.svg';

import {IntlProvider} from "react-intl"

export const ThemeContext = React.createContext(null);
export const ScreenWidthContext = React.createContext(0);
export const FontLoadedContext = React.createContext(false);

import themeObjectFromYaml from "../theme/theme.yaml";

import { navigate } from "gatsby"

import messages_ar from "../translations/ar.json";
import messages_en from "../translations/en.json";

import RTL from './rtl';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';

const messages = {
    'en': messages_en,
    'ar': messages_ar,
};
const languages = [
  {code: 'en', name: 'English', urlprefix: 0, icon: UsIcon, isRTL: ()=> false },
  {code: 'ar', name: 'العربية', urlprefix: 'ar', icon: SaIcon, isRTL: ()=> true},
];

class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      font400loaded: false,
      font600loaded: false,
      screenWidth: 0,
      headerMinimized: false,
      theme: themeObjectFromYaml,
      language: languages[0],
    };

    if (typeof window !== `undefined`) {
      this.loadFont("font400", "Open Sans", 400);
      this.loadFont("font600", "Open Sans", 600);
    }
    this.handleLanguageClick = this.handleLanguageClick.bind(this);
  }

  timeouts = {};

  componentDidMount() {
    this.setState({
      screenWidth: getScreenWidth()
    });
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resizeThrottler, false);
    }
  }

  resizeThrottler = () => {
    return timeoutThrottlerHandler(this.timeouts, "resize", 100, this.resizeHandler);
  };

  resizeHandler = () => {
    this.setState({ screenWidth: getScreenWidth() });
  };

  isHomePage = () => {
    if (this.props.location.pathname === "/" ||
        this.props.location.pathname === "/en" ||
        this.props.location.pathname === "/ar") {
      return true;
    }

    return false;
  };

  loadFont = (name, family, weight) => {
    const font = new FontFaceObserver(family, {
      weight: weight
    });

    font.load(null, 10000).then(
      () => {
        this.setState({ [`${name}loaded`]: true });
      },
      () => {
      }
    );
  };

  handleLanguageClick = (e) => {
    const location = window.location.pathname;
    const newLocation= location.startsWith("/ar") || location.startsWith("/en")
      ?
      location
       .substring(3,location.length): 
       location;
       const selectedLanguage = languages.find(obj => {
        return obj.code === e.language;
      })
    navigate("/"+selectedLanguage.code+newLocation);
    this.setState({language:selectedLanguage}); 
   }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            pages: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
              sort: { fields: [fields___prefix], order: ASC }
            ) {
              edges {
                node {
                  fields {
                    slug
                    prefix
                    language
                  }
                  frontmatter {
                    title
                    menuTitle
                    language
                    description
                  }
                }
              }
            }
            footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
              id
              html
              excerpt(format: HTML)
            }
          }
        `}
        render={data => {
          const { children } = this.props;
          const {
            footnote: { html: footnoteHTML },
            pages: { edges: pages }
          } = data;
         const pageProps ={
          language: this.state.language,
          handleLanguageClick:  this.handleLanguageClick}
          const theme = createTheme({
            direction: this.state.language.isRTL() ? 'rtl': 'ltr',
        });
         const childrenWithProps = React.Children.map(children, (child) =>
         {
          console.log("child ", child);
          console.log("pageProps ", pageProps);

          return React.cloneElement(child, {language: pageProps.language, theme: pageProps.theme, handleLanguageClick: pageProps.handleLanguageClick})


        });
          console.log("childrenWithProps ", childrenWithProps);

          return (
            
            <ThemeContext.Provider value={this.state.theme}>
            <FontLoadedContext.Provider value={this.state.font400loaded}>
              <ScreenWidthContext.Provider value={this.state.screenWidth}>
              <IntlProvider locale={this.state.language.code} messages={messages[this.state.language.code]}>
              <RTL>
               <MuiThemeProvider theme={theme}>
                <React.Fragment>
                <Header
                    languages={languages}
                    language={this.state.language}
                    path={this.props.location.pathname}
                    pages={pages}
                    theme={this.state.theme}
                    handleLanguageClick={this.handleLanguageClick}
                  />
                  <main>{childrenWithProps}</main>

                  <Footer language={this.state.language} html={footnoteHTML} theme={this.state.theme} />

                  {/* --- STYLES --- */}
                  <style jsx>{`
                    main {
                      min-height: 80vh;
                    }
                  `}</style>
                  <style jsx global>{`
                    html {
                      box-sizing: border-box;
                    }
                    *,
                    *:after,
                    *:before {
                      box-sizing: inherit;
                      margin: 0;
                      padding: 0;
                    }
                    body {
                      margin: 0px;
                      font-family: ${this.state.font400loaded
                        ? "'Open Sans', sans-serif;"
                        : "Arial, sans-serif;"};
                    }
                    h1,
                    h2,
                    h3 {
                      font-weight: ${this.state.font600loaded ? 600 : 400};
                      line-height: 1.1;
                      letter-spacing: -0.03em;
                      margin: 0;
                    }

                    h1 {
                      letter-spacing: -0.04em;
                    }
                    p {
                      margin: 0;
                    }
                    strong {
                      font-weight: ${this.state.font600loaded ? 600 : 400};
                    }
                    a {
                      text-decoration: none;
                      color: #7e7e7e;
                    }
                    main {
                      width: 100%;
                      display: block;
                    }

                  `}</style>
                </React.Fragment>
                </MuiThemeProvider>
                </RTL>
                </IntlProvider>

              </ScreenWidthContext.Provider>
            </FontLoadedContext.Provider>
          </ThemeContext.Provider>


          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;

//eslint-disable-next-line no-undef
/*
export const postQuery = graphql`
  query LayoutQuery {
    pages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
      sort: { fields: [fields___prefix], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            Title
          }
        }
      }
    }
    footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
      id
      html
    }
  }
`;

*/
